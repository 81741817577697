body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  text-decoration: none !important;
  color: inherit !important;
}

button {
  text-decoration: none !important;
}

:focus {
  outline: 0 !important;
}

.gradButton {
  background-image: linear-gradient(to right, #3aafa9, #17252a);
}

.gradColor {
  color: linear-gradient(to right, #3aafa9, #17252a);
}

.disabledGrad {
  background-color: #cccccc;
  color: #666666;
}

.facebook {
  background-color: rgba(9, 30, 66);
  border-radius: inherit;
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
